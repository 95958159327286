import { Store } from "pullstate";

export const UserStore = new Store({
  userDoc: null,
  townDoc: null,
  logoInfo: null,
  notifications: null,
  unreads: null,
  instaScan: false,
  platform: null,
  scanData: null
});