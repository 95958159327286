import { IonAlert, IonBackButton, IonButton, IonButtons, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonMenuButton, IonModal, IonPage, IonProgressBar, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonSpinner, IonText, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import { addSharp, closeSharp, cloudUploadOutline, imageOutline, peopleSharp, trashOutline } from 'ionicons/icons';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { UserStore } from '../store/UserStore';

import firebaseApp from '../firebase';
import { toast } from '../components/Toast/Toast';
// import {
//   BrowserView,
//   MobileView,
//   isBrowser,
//   isMobile
// } from "react-device-detect";

const GroupDetail = (props) => {
  const id = props.match.params.id;
  const pageRef = useRef();
  const [ showLoading, setShowLoading ] = useState(false);
  const [ details, setDetails ] = useState(null);
  const [ members, setMembers ] = useState(null);
  const userDoc = UserStore.useState(s => s.userDoc);
  const [ requested, setRequested ] = useState(null);
  console.log(userDoc)

  const request = () => {
    console.log('request');
    var makeRequest = firebaseApp.functions().httpsCallable('groupMemberRequest');
    makeRequest({
      firstName: userDoc.firstName,
      lastName: userDoc.lastName,
      email: userDoc.email,
      groupId: id,
      groupName: details.name,
      adminEmail: details.adminEmail,
      photoURL: userDoc.photoURL
    }).then((result) => {
      // Read result of the Cloud Function.
      console.log(result.data);
      // var sanitizedMessage = result.data.text;
      // ...
    });

    firebaseApp.firestore().collection('groups').doc(id).collection('requests').doc(userDoc.id)
    .set({
      userId: userDoc.id,
      when: Date.now(),
      firstName: userDoc.firstName,
      lastName: userDoc.lastName,
      email: userDoc.email,
      groupId: id,
      groupName: details.name,
      adminEmail: details.adminEmail,
      unread: true,
      photoURL: userDoc.photoURL
    })
    .then(() => {
      getDetails();
      console.log("Request successfully written!");
    })
    .catch((error) => {
      getDetails();
      console.error("Error writing document: ", error);
    });
  }

  const cxlRequest = () => {
    console.log('cxlRequest')
    firebaseApp.firestore().collection('groups').doc(id).collection('requests').doc(userDoc.id)
    .delete()
    .then((res) => {
      getDetails();
      console.log('deleted =>', res)
    })
    .catch(err => console.log(err));
  }
  const getDetails = () => {
    firebaseApp.firestore().collection('groups').doc(id)
    .get()
    .then((doc) => {
      console.log(doc.data())
      let id = doc.id;
      let data = doc.data();
      setDetails({id, ...data})
    })
    .catch((err) => console.log(err));

    firebaseApp.firestore().collection('groups').doc(id).collection('requests').doc(userDoc.id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        setRequested(true);
      } else {
          setRequested(false);
          console.log("No such request!");
      }
      }).catch(function(error) {
          console.log("Error getting resquest:", error);
      });
    
  }
  useEffect(() => {
    getDetails();
  }, [])
  useEffect(() => {
    console.log(userDoc);
  }, [userDoc]);
  useEffect(() => {
    console.log(requested);
  }, [requested]);

  if (!details) {
    return (
    <IonPage ref={pageRef} mode="ios">
      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Please wait...'}
        duration={3000}
      />
    </IonPage>
    )
  }
  if (details) {
    return (
      <IonPage ref={pageRef} mode="ios">
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
          duration={3000}
        />
        <IonHeader translucent={true} mode="ios">
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text="Groups" />
            </IonButtons>
            <IonTitle>{details.name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonHeader translucent={true} mode="ios" collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{details.name}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonGrid>
            <IonRow>
              <IonCol>
              <div className="maskie ion-margin-vertical whiteBg"><img className="maskieImg" src={details.logoUrl} alt="logo" /></div>
                <IonList className="roundedList ion-padding-vertical">
                  <IonItem>
                    <IonLabel position="stacked">Name</IonLabel>
                    <IonText>{details.name}</IonText>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">Type</IonLabel>
                    <IonText>{details.type}</IonText>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">Admin Contact</IonLabel>
                    <IonText>{details.adminEmail}</IonText>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel position="stacked">Message</IonLabel>
                    <IonText>{details.blurb}</IonText>
                  </IonItem>
                </IonList>
              </IonCol>
            </IonRow>
            <IonRow>
              {/* <IonCol>
                <h2>Members</h2>
              </IonCol> */}
            </IonRow>
            <IonRow>
              {requested === false ?
              <IonCol sizeXs="12" sizeMd="4">
                <IonButton onClick={request} disabled={requested} expand="block">{requested ? 'Requested' : 'Request to Join'}</IonButton>
              </IonCol>
              : null}
              {requested === true ?
              <IonCol sizeXs="12" sizeMd="4">
                <IonButton fill="outline" color="danger" onClick={cxlRequest} expand="block">Cancel Request</IonButton>
              </IonCol>
              : null}
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};

export default GroupDetail;
