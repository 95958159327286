import React, { useEffect, useState, useRef } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonSlide, IonSlides, IonModal,
  IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonTitle, IonToolbar, IonButton, IonLoading, IonSpinner, 
  IonButtons, IonItem, IonLabel, IonList, IonCheckbox, IonBadge, IonText, IonRefresher, IonRefresherContent } from '@ionic/react';
import { alarmOutline, cameraSharp, chevronDownCircleOutline, informationCircleOutline, notificationsOutline } from 'ionicons/icons';
import firebaseApp from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import './Tab1.css';
import dayjs from 'dayjs';
import QRCode from "react-qr-code";
import PassHelp from '../components/PassHelp';

import { UserStore } from '../store/UserStore';
import { getNotifications } from '../App';

const firestore = firebaseApp.firestore();

const Pass = () => {
  const pageRef = useRef();
  const [ TownPass, setTownPass ] = useState(null);
  const [ DepTownPasses, setDepTownPasses ] = useState(null);
  const [ user, loading ] = useAuthState(firebaseApp.auth());
  const userDoc = UserStore.useState(s => s.userDoc);
  const [ dependents, setDependents ] = useState([]);
  const [ currentDependent, setCurrentDependent ] = useState(null);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ depModal, setDepModal ] = useState(false);
  const [ showLoading, setShowLoading ] = useState(false);
  const [ checkin, setCheckin ] = useState({date: null, agree: false, read: false});
  const [ valid, setValid ] = useState(false);
  const [ helpModal, setHelpModal ] = useState(false);
  const [ result, setResult ] = useState(null);
  const unreads = UserStore.useState(s => s.unreads);
  const [ unreadCount, setUnreadCount ] = useState(null);

  const getUser = () => {
    setShowLoading(true);
    firestore.collection('users').doc(user.uid).get()
    .then(doc => {
      const data = doc.data();
      // console.log(data);
      UserStore.update(s => {
        s.userDoc = data;
      })
      setShowLoading(false);
    })
    .then(() => {
      firestore.collection('users').doc(user.uid).collection('TownPasses').doc(now.format('dddd, MMM DD, YYYY'))
      .get()
      .then((doc) => {
        const data = doc.data();
        // console.log(data)
        if (doc.id === now.format('dddd, MMM DD, YYYY')) {
          setValid(true)
        }
        setTownPass(data !== undefined ? data : null);
      })
      .catch((err) => console.log(err))
    })
    .catch(error => {
      console.log(error);
    })
  }
  
  const getDependents = () => {

    const dependentsList = [];
    firebaseApp.firestore().collection('users').doc(user.uid).collection('dependents')
    .get()
    .then((querySnapshot) => {
      // console.log(querySnapshot.docs)
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        const id = doc.id;
        const data = doc.data();
        dependentsList.push({id, ...data})

    })
    if (dependentsList.length > 0) {
      setDependents(dependentsList);
      console.log(dependentsList)
    }
      setShowLoading(false);
    });  
  }
  
  const getPass = () => {
    if (dependents) {
      const depPasses = []
      dependents.forEach((dep) => {
        console.log(dep.id)
        firestore.collection('users').doc(user.uid).collection('dependents').doc(dep.id).collection('TownPasses').doc(now.format('dddd, MMM DD, YYYY'))
        .get()
        .then((doc) => {
          const data = doc.data();
          if (data) {
            depPasses.push(data)
          }
        })
        .catch((err) => console.log(err))
      })
      setDepTownPasses(depPasses);
    }
  } 

  useEffect(() => {
    getUser();
    getDependents();
    getPass();
  }, []);

  useEffect(() => {
    getPass();
  }, [dependents]);
  
  useEffect(() => {
    if (DepTownPasses) {
      setShowLoading(true);
      setTimeout(() => {
        setShowLoading(false)
      }, 300);
    }
  }, [DepTownPasses])
  const checkInModal = () => {
    setIsOpen(true);
  }
  const checkInDepModal = (dep) => {
    setCurrentDependent(dep);
    setDepModal(true);
  }
  const now = dayjs(new Date());
  
  const slideOpts = {
    initialSlide: 0,
  };

  const setPass = () => {
    setShowLoading(true);
    setIsOpen(false);
    firestore.collection('users').doc(user.uid).collection('TownPasses').doc(now.format('dddd, MMM DD, YYYY'))
    .set({
      time: now.format(),
      readableDate: now.format('dddd, MMM DD, YYYY'),
      firstName: userDoc.firstName,
      lastName: userDoc.lastName,
      phone: userDoc.phone,
      email: user.email,
      userId: user.uid,
      photoURL: user.photoURL,
      valid: true,
    })
    .then((response) => {
      //  console.log(response.id);
      firestore.collection('TownPasses').doc(user.uid).collection('TownPasses').doc(now.format('dddd, MMM DD, YYYY'))
      .set({
        time: now.format(),
        readableDate: now.format('dddd, MMM DD, YYYY'),
        firstName: userDoc.firstName,
        lastName: userDoc.lastName,
        phone: userDoc.phone,
        email: user.email,
        userId: user.uid,
        photoURL: user.photoURL,
        valid: true,
      })
      .then((res) => {
        console.log(res);
        getUser();
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoading(false);
      })
    })
  }
  const setDepPass = () => {
    setShowLoading(true);
    setDepModal(false);
    firestore.collection('users').doc(user.uid).collection('dependents').doc(currentDependent.id).collection('TownPasses').doc(now.format('dddd, MMM DD, YYYY'))
    .set({
      time: now.format(),
      readableDate: now.format('dddd, MMM DD, YYYY'),
      firstName: currentDependent.firstName,
      lastName: currentDependent.lastName,
      phone: userDoc.phone,
      email: user.email,
      depId: currentDependent.id,
      photoURL: currentDependent.maskie,
      valid: true,
      dependent: true,
      guardian: user.uid
    })
    .then(() => {
      firestore.collection('TownPasses').doc(user.uid).collection('TownPasses').doc(now.format('dddd, MMM DD, YYYY'))
      .set({
        time: now.format(),
        readableDate: now.format('dddd, MMM DD, YYYY'),
        firstName: currentDependent.firstName,
        lastName: currentDependent.lastName,
        phone: userDoc.phone,
        email: user.email,
        userId: user.uid,
        photoURL: currentDependent.maskie,
        valid: true,
        dependent: true,
        guardian: user.uid
      })
      .then((res) => {
        console.log(res);
        getDependents();
        setShowLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoading(false);
      })
    })
  }
  function doRefresh(event) {
    console.log('Begin async operation');
    getNotifications();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.detail.complete();
    }, 2000);
  }
  useEffect(() => {
    if (unreads && unreads.length > 0) {
      setUnreadCount(unreads.length);
    }
  }, [unreads])

  useEffect(() => {
    console.log(result)
  }, [result])
  // useEffect(() => {
  //   console.log(dependents);
  // }, [dependents])
  // useEffect(() => {
  //   console.log(TownPass);
  // }, [TownPass])
  // useEffect(() => {
  //   console.log(DepTownPasses);
  // }, [DepTownPasses])
  if (!loading) {
    return (
      <IonPage ref={pageRef}>
        <IonLoading
          cssClass='appLoading'
          isOpen={showLoading}
          spinner={null}
          message={'Please wait...'}  
          />
        <IonHeader translucent="true">
          <IonToolbar>
            <IonTitle>TownPass</IonTitle>
            <IonButtons slot="end">
              <IonButton className="widerBtn" routerLink={'/pass/notifications'} routerDirection="forward">
                <IonIcon className="customBtn" slot="icon-only" icon={notificationsOutline} />
                {unreadCount ? <IonBadge color="danger" className="notifIcon">{unreadCount < 100 ? unreadCount : <IonText className="swd">{'\u2B24'}</IonText>}</IonBadge> : null}
              </IonButton>
              <IonButton onClick={() => setHelpModal(!helpModal)}>
                <IonIcon slot="icon-only" icon={informationCircleOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent
              pullingIcon={chevronDownCircleOutline}
              pullingText="Pull to refresh"
              refreshingSpinner="crescent"
              refreshingText="Refreshing...">
            </IonRefresherContent>
          </IonRefresher>
          <IonHeader translucent="true" collapse="condense">
            <IonToolbar>
              <IonTitle size="large">TownPass</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonGrid className="cardContainer" style={{height: '100%'}}>
            <IonRow style={{height: '100%'}}>
              <IonCol style={{height: '100%'}}>
                <IonSlides pager={true} options={slideOpts} style={{height: '100%'}}>
                  <IonSlide className="cardHolder" style={{height: '100%'}}>
                    <IonCard className="passCard">
                      {user ? 
                      <div className="avatarHolder">
                        <div className="userAvatar" style={{ backgroundImage: `url(${user.photoURL})` }}>
                          <div className="avatarImg" src={user.photoURL} alt="user"/>
                        </div>
                      </div>
                      : null}
                      {userDoc ?
                        <>
                          <IonCardHeader className="ion-no-padding">
                            <IonCardTitle>{userDoc.firstName}</IonCardTitle>
                            <IonCardSubtitle>{now.format('dddd, MMM DD')}</IonCardSubtitle>
                          </IonCardHeader>
                          {showLoading ?
                          <div className="qrPlaceholder">
                            <IonSpinner/>
                          </div>
                          :
                          <IonCardContent>
                            
                            {TownPass ?
                            <>
                              <div className="qrHolder">
                                <div className="qrCode"><QRCode fgColor={'#000'} level={'L'} size={256} value={userDoc.id}/></div>
                              </div>
                            </>
                            : 
                            <p className="ion-text-center ion-padding-vertical">
                              {userDoc.firstName} does not yet have a TownPass for {now.format('dddd, MMM DD, YYYY')}. <br/>
                              <IonButton className="ion-margin-top" fill="outline" size="small" color="medium" onClick={checkInModal}>Check {userDoc.firstName} in</IonButton>
                            </p>
                            }
                            <div className="scanHolder">
                                <IonButton 
                                  size="small" 
                                  fill="clear" 
                                  color="success" 
                                  onClick={() =>
                                    UserStore.update(s => {
                                      s.instaScan = true;
                                    })
                                  }>
                                  <IonIcon slot="start" icon={cameraSharp} color="success"/>
                                  Scan Facility Code
                                </IonButton>
                              </div>
                          </IonCardContent>
                          }
                        </>
                      : <IonSpinner/>}
                    </IonCard>
                  </IonSlide>
                  {dependents && dependents.length > 0 ? dependents.map((dep, i) => (
                    <IonSlide key={i} style={{height: '100%'}}>
                      <IonCard className="passCard">
                        <div className="avatarHolder">
                          <div className="userAvatar" style={{ backgroundImage: `url(${dep.maskie})` }}>
                            <div className="avatarImg" src={dep.maskie} alt="user"/>
                          </div>
                        </div>
                          <>
                            <IonCardHeader className="ion-no-padding">
                              <IonCardTitle>{dep.firstName}</IonCardTitle>
                              <IonCardSubtitle>{now.format('dddd, MMM DD')}</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                              {DepTownPasses && DepTownPasses.find(o => o.depId === dep.id) ? 
                                <>
                                  <div className="qrHolder">
                                    <div className="qrCode"><QRCode className="qrCode" fgColor={'#000'} level={'L'} value={DepTownPasses.find(o => o.depId === dep.id).depId}/></div>
                                  </div>
                                  
                                </>
                              : 
                              <p className="ion-text-center ion-padding-vertical">
                                {dep.firstName} does not yet have a TownPass for {now.format('dddd, MMM DD, YYYY')}. <br/>
                                <IonButton className="ion-margin-top" fill="outline" size="small" color="medium" onClick={() => checkInDepModal(dependents[i])}>Check {dep.firstName} in</IonButton>
                              </p>
                              }
                              <div className="scanHolder">
                                <IonButton 
                                  size="small" 
                                  fill="clear" 
                                  color="success" 
                                  onClick={() =>
                                    UserStore.update(s => {
                                      s.instaScan = true;
                                    })
                                  }>
                                  <IonIcon slot="start" icon={cameraSharp} color="success"/>
                                  Scan Facility Code
                                </IonButton>
                              </div>
                            </IonCardContent>
                          </>
                      </IonCard>
                    </IonSlide>
                  )) 
                  : null}
                </IonSlides>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonModal 
            // ref={pageRef}
            swipeToClose
            mode="ios"
            presentingElement={pageRef.current}
            isOpen={isOpen}
            onDidDismiss={() => setIsOpen(false)}
            cssClass='my-custom-class'>
            
            <IonHeader translucent>
              <IonToolbar>
                <IonTitle>COVID-19 SCREENING</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setIsOpen(false)}><IonIcon slot="icon-only" icon="close" /></IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding">
              <IonGrid>
                <IonRow>
                  <IonCol className="ion-padding-horizontal">
                    <h2>Please read the following:</h2>
                    <ul className="termsList">
                      <li>In the last 14 days, have you had close physical contact with someone who tested positive for COVID-19? This includes receiving a COVID Alert exposure notification. NOTE: "Close physical contact" means being less than 2 metres away in the same room, workspace, or area or living in the same home.</li>
                      <li>In the last 14 days, have you had close physical contact with someone who returned from outside of Canada in the last 2 weeks?</li>
                      <li>Have you travelled outside of Canada in the last 14 days?</li>
                      <li>Do you have any of the following symptoms: fever, new onset of cough, worsening chronic cough, shortness of breath, difficulty breathing, sore throat, difficulty swallowing, decrease or loss of sense of taste or smell, chills, headaches, unexplained fatigue/malaise/muscle aches (myalgias), nausea/vomiting, diarrhea, abdominal pain, pink eye (conjunctivitis), runny nose/nasal congestion without other known cause, and if you're over 70: delirium, unexplained or increased number of falls, acute functional decline, worsening of chronic conditions?</li>
                    </ul>
                    <p>If you answered YES to ANY COVID-19 screening questions, you are NOT permitted to enter the facility. You should visit a COVID-19 Assessment Centre or contact your health care provider for follow up.</p>
                    <IonList className="seeThru">
                      <IonItem className="seeThru">
                        <IonCheckbox checked={checkin.agree} onIonChange={e => setCheckin({...checkin, agree: e.detail.checked})} />
                        <IonLabel className="ion-text-wrap">I agree that I have answered NO to all of the covid-screening questions and that to the best of my knowledge, myself and/or the minor on whose behalf I am signing, do not have COVID-19.</IonLabel>
                      </IonItem>
                      <IonItem className="seeThru">
                        <IonCheckbox checked={checkin.read} onIonChange={e => setCheckin({...checkin, read: e.detail.checked})} />
                        <IonLabel className="ion-text-wrap">I have read and understood the above, and I confirm that my above answers are true to the best of my knowledge and ability. </IonLabel>
                      </IonItem>
                    </IonList>
                    <p><strong>By clicking here, I ({user.displayName}) understand that I am applying my digital signature.</strong></p>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
            <IonButton disabled={!checkin.agree || !checkin.read ? true : false} onClick={setPass}>I understand</IonButton>
          </IonModal>
          <IonModal 
            swipeToClose
            mode="ios"
            presentingElement={pageRef.current}
            isOpen={depModal}
            onDidDismiss={() => setDepModal(false)}
            cssClass='my-custom-class'>
            
            <IonHeader translucent>
              <IonToolbar>
                <IonTitle>COVID-19 SCREENING</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setDepModal(false)}><IonIcon slot="icon-only" icon="close" /></IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding">
              <IonGrid>
                <IonRow>
                  <IonCol className="ion-padding-horizontal">
                    <h2>Please read the following:</h2>
                    <ul className="termsList">
                      <li>In the last 14 days, has {currentDependent && currentDependent.firstName} had close physical contact with someone who tested positive for COVID-19? This includes receiving a COVID Alert exposure notification. NOTE: "Close physical contact" means being less than 2 metres away in the same room, workspace, or area or living in the same home.</li>
                      <li>In the last 14 days, has {currentDependent && currentDependent.firstName} had close physical contact with someone who returned from outside of Canada in the last 2 weeks?</li>
                      <li>Has {currentDependent && currentDependent.firstName} travelled outside of Canada in the last 14 days?</li>
                      <li>Does {currentDependent && currentDependent.firstName} have any of the following symptoms: fever, new onset of cough, worsening chronic cough, shortness of breath, difficulty breathing, sore throat, difficulty swallowing, decrease or loss of sense of taste or smell, chills, headaches, unexplained fatigue/malaise/muscle aches (myalgias), nausea/vomiting, diarrhea, abdominal pain, pink eye (conjunctivitis), runny nose/nasal congestion without other known cause, and if you're over 70: delirium, unexplained or increased number of falls, acute functional decline, worsening of chronic conditions?</li>
                    </ul>
                    <p>If you answered YES to ANY COVID-19 screening questions, {currentDependent && currentDependent.firstName} is NOT permitted to enter the facility. You should visit a COVID-19 Assessment Centre or contact your health care provider for follow up.</p>
                    <IonList className="seeThru">
                      <IonItem className="seeThru">
                        <IonCheckbox checked={checkin.agree} onIonChange={e => setCheckin({...checkin, agree: e.detail.checked})} />
                        <IonLabel className="ion-text-wrap">I agree that I have answered NO to all of the covid-screening questions and that to the best of my knowledge, myself and/or the minor on whose behalf I am signing, do not have COVID-19.</IonLabel>
                      </IonItem>
                      <IonItem className="seeThru">
                        <IonCheckbox checked={checkin.read} onIonChange={e => setCheckin({...checkin, read: e.detail.checked})} />
                        <IonLabel className="ion-text-wrap">I have read and understood the above, and I confirm that my above answers are true to the best of my knowledge and ability. </IonLabel>
                      </IonItem>
                    </IonList>
                    <p><strong>By clicking here, I ({user.displayName}) understand that I am applying my digital signature.</strong></p>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
            <IonButton disabled={!checkin.agree || !checkin.read ? true : false} onClick={setDepPass}>I understand</IonButton>
          </IonModal>
          <IonModal 
            // ref={pageRef}
            swipeToClose
            mode="ios"
            presentingElement={pageRef.current}
            isOpen={helpModal}
            onDidDismiss={() => setHelpModal(false)}
            cssClass='my-custom-class'>
            <IonHeader translucent>
              <IonToolbar>
                <IonTitle>TownPass Help</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setHelpModal(false)}>
                    <IonIcon slot="icon-only" icon="close" />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
            {userDoc ? 
            <PassHelp name={userDoc.firstName} />
            : null }
            </IonContent> 
          </IonModal>
        </IonContent> 
      </IonPage>
    );
  }
  if (loading) {
    return (
      <IonLoading
      cssClass='appLoading'
      isOpen={showLoading}
      spinner={null}
      message={'Please wait...'}  
      />
    )
  }
};

export default Pass;
