import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLoading,
  IonPage,
  IonContent,
  IonBadge,
  isPlatform,
  getPlatforms
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipsisHorizontalOutline, scanCircleOutline, todayOutline } from 'ionicons/icons';

/* Pages */
import PlanIt from './pages/PlanIt';
import Pass from './pages/Pass';
import More from './pages/More';
import Profile from './pages/Profile';
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import Register from './pages/Register';
import Setup from './pages/Setup';
import Dependents from './pages/Dependents';
import Facilities from './pages/Facilities';
import Facility from './pages/Facility';
import Groups from './pages/Groups';
import GroupDetail from './pages/GroupDetail';
import Notifications from './pages/Notifications';

/* Pullstate Store */
import { UserStore } from "./store/UserStore";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Firebase Stuff */
import { useAuthState } from 'react-firebase-hooks/auth';
import firebaseApp from 'firebase';

/* Components */
import OnBoardingContainer from './components/OnboardContainer';
import VerifyContainer from './components/VerifyContainer';
import InstaScan from './components/InstaScan';

/* Hooks */
import useLongPress from './hooks/useLongPress';
import { BarcodeScanner } from '@ionic-native/barcode-scanner';

const firestore = firebaseApp.firestore();

export const getNotifications = () => {
  console.log('getting notifications')
  firestore.collection('users').doc(firebaseApp.auth().currentUser.uid).collection('notifications')
    .get()
    .then((querySnapshot) => {
      const notList = [];
      const unreads = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();

        notList.push({id, ...data})
        if (data.unread) {
          unreads.push({id, ...data})
        }
      })
      UserStore.update(s => {
        s.notifications = notList;
      })
      UserStore.update(s => {
        s.unreads = unreads;
      })
    })
}
const App = () => {
  const [ user, loading, error ] = useAuthState(firebaseApp.auth());
  const [ completed, setCompleted ] = useState(null);
  const [ pageLoading, setPageLoading ] = useState(true);
  const notifications = UserStore.useState(s => s.notifications);
  const unreads = UserStore.useState(s => s.unreads);
  const platform = UserStore.useState(s => s.platform);
  const open = UserStore.useState(s => s.instaScan);
  const [ unreadCount, setUnreadCount ] = useState(null);
  // console.log(isPlatform('mobileweb'))

  const onLongPress = () => {
    console.log('longpress is triggered');
    if (platform !== 'native') {
      UserStore.update(s => {
        s.instaScan = true;
      })
    } else {
      openScanner();
    }
    console.log('v1')
  };
  const openScanner = async () => {
    const data = await BarcodeScanner.scan();
    console.log(`Barcode data: ${data.text}`);
    if (!data.cancelled) {
      UserStore.update(s => {
        s.scanData = data.text;
        s.instaScan = true;
      });
    }
  };
  const onClick = () => {
    return;
  }

  const defaultOptions = {
    shouldPreventDefault: false,
    delay: 500,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
  
  const getUserDoc = () => {
    if (user !== null) {
      // console.log(user)
      firestore.collection('users').doc(user.uid)
      .get()
      .then((doc) => {
        const data = doc.data();
        UserStore.update(s => {
          s.userDoc = {...data, photoURL: user.photoURL};
        })
        if (data.completed) {
          setCompleted(true);
        }
      })
      .catch((error) => console.log(error));

      getNotifications();
    }
  }
  
  useEffect(() => {
    if (user) {
      getUserDoc();
    }
    // console.log(user)
  }, [user])

  useEffect(() => {
    if (user && user.emailVerified === false) {
      user.sendEmailVerification().then(() => {
        console.log('email verification sent')
      }).catch((error) => {
        console.log(error)
      })
    }
  }, [user])

  useEffect(() => {
    setTimeout(() => {
      setPageLoading(false);
    }, 600)
  }, [])
  
  useEffect(() => {
    if (isPlatform('mobileweb')) {
      console.log('is mobileweb');
      UserStore.update(s => {
        s.platform = 'mobileweb';
      })
    } else {
      UserStore.update(s => {
        console.log('is native');
        s.platform = 'native';
      })
    }
  }, []);

  useEffect(() => {
    console.log(platform);
  }, [platform]);

  useEffect(() => {
    if (unreads && unreads.length > 0) {
      setUnreadCount(unreads.length);
    }
    if (unreads && unreads.length === 0) {
      console.log(unreads.length);
      setUnreadCount(null)
    }
  }, [ unreads, notifications ])

  if (error) {
    return (
      <IonApp>
        <div>
          <p>Error: {error}</p>
        </div>
      </IonApp>
    );
  }
  if (!user && !loading) {
    // console.log('no user, not loading')
    return (
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet id="main">
            <Redirect exact from="/" to="/login"/>
            <Redirect exact from="/more" to="/login"/>
            <Redirect exact from="/pass" to="/login"/>
            <Route root path="/login" component={Login} exact /> 
            <Route root path="/forgot" component={Forgot} exact />
            <Route root path="/register" component={Register} exact /> 
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    );
  }
  if (user && !user.emailVerified) {
    // console.log('no user, not verified')
    return (
      <IonApp>
        <VerifyContainer user={user} />
      </IonApp>
    )
  }
  if (user && user.emailVerified && completed === true ) {
    // console.log('user, verified, complete')
    return (
      <IonApp mode="ios">
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Redirect from="/register" to="/pass" exact/>
              <Redirect from="/login" to="/pass" exact/>
              <Route path="/plan" component={PlanIt} exact={true} />
              <Route path="/plan/:id" component={Facilities} exact={true} />
              <Route path="/plan/:id/:name" component={Facility} exact={true} />
              <Route path="/pass" component={Pass} exact />
              <Route path="/pass/notifications" component={Notifications} exact />
              <Route path="/more" component={More} exact />
              <Route path="/setup" component={Setup} exact />
              <Route path="/more/profile" component={Profile}/>
              <Route path="/more/dependents" component={Dependents}/>
              <Route path="/more/groups" component={Groups} exact={true} />
              <Route path="/more/groups/:id" component={GroupDetail} exact={true} />
              <Route path="/" render={() => <Redirect to="/pass" />} exact={true} />
            </IonRouterOutlet>
            <IonTabBar className="seeThru" translucent="true" slot="bottom">
              <IonTabButton tab="tab1" href="/plan">
                <IonIcon icon={todayOutline} />
                <IonLabel>Plan</IonLabel>
              </IonTabButton>
              <IonTabButton tab="tab2" href="/pass" {...longPressEvent}>
                <IonIcon icon={scanCircleOutline} />
                {unreadCount ? <IonBadge color="danger">{unreadCount}</IonBadge> : null}
                <IonLabel>Scan</IonLabel>
              </IonTabButton>
              <IonTabButton tab="tab3" href="/more">
                <IonIcon icon={ellipsisHorizontalOutline} />
                <IonLabel>More</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
          {open ?
          <InstaScan platform={platform} open={open} />
          : null }
        </IonReactRouter>
        
      </IonApp>
    )
  } 
  if (user && user.emailVerified && completed === false) {
    // console.log('user, verified, incomplete')
    return (
      <IonApp>
        <OnBoardingContainer/>
      </IonApp>
    )
  }
  if (pageLoading || loading || completed === null || unreadCount === null) {
    // console.log('not ready')
    return (
      <IonApp>
        <IonPage>
          <IonLoading
            cssClass='appLoading'
            isOpen={true}
            message={'Please wait...'}
            spinner={null}
            duration={3000}
          />
          <IonContent>
            {/* <h2>Not ready</h2> */}
          </IonContent>
        </IonPage>
      </IonApp>
    );
  }
};

export default App;
