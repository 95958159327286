import {IonCol, IonGrid, IonIcon, IonRow} from '@ionic/react';
import { cameraSharp, qrCodeSharp } from 'ionicons/icons';
import React from 'react';
import './ExploreContainer.css';

const PassHelp = ({name}) => {

  return (
    <IonGrid>
      <IonRow>
        <IonCol className="ion-padding">
            <p className="ion-text-center">To visit participating facilities, you are required to complete a daily COVID-19 screening questionnaire for yourself and any dependents that will be joining you. Don't worry, it's easy!</p>
            <p className="ion-text-center ion-padding-vertical">Simply use the "Check {name} In" button on your pass and complete the questions. Follow the same process for your dependents as well.</p>
            <p className="ion-text-center"><IonIcon icon={qrCodeSharp} size="large" /></p>
            <p className="ion-text-center">Once you've completed your daily questionnaire, a QR Code will be generated on your pass. This is your proof that you've completed the questionnaire, and will allow the facilities to record your visit for contact tracing purposes, should it be necessary at a later date.</p>
            <p className="ion-text-center"><IonIcon icon={cameraSharp} size="large" /></p>
            <p className="ion-text-center">Some facilities may not have attendants to scan you in. In those cases, they will provide a QR Code that you will scan prior to entering. This again will allow the facilities to record your visit for contact tracing purposes while also recording that you completed your daily questionnaire.</p>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default PassHelp;
