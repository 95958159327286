import React from 'react';
import { IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { happyOutline } from 'ionicons/icons';
import OnboardingContainer from '../components/OnboardContainer';
import './Tab1.css';

const Setup = () => {

  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Welcome</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Welcome <IonIcon icon={happyOutline} className="titleIcon"/></IonTitle>
          </IonToolbar>
        </IonHeader>
        <OnboardingContainer/>
      </IonContent>
    </IonPage>
  );
};

export default Setup;
