import React, { useEffect, useState } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, 
  IonPage, IonRow, IonTitle, IonToolbar, IonButton, IonLoading, IonBackButton, IonButtons } from '@ionic/react';
import { UserStore } from '../store/UserStore';
import './Tab1.css';
import firebaseApp from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const Profile = () => {
  const [ user ] = useAuthState(firebaseApp.auth());
  const userDoc = UserStore.useState(s => s.userDoc);

  // useEffect(() => {
  //   console.log(userDoc);
  //   console.log(user.photoURL);
  // }, [userDoc])
  return (
    <IonPage>
    <IonLoading isOpen={!userDoc ? true : false}/>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Profile</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard>
                {user ? 
                  <img src={user.photoURL} alt="user"/>
                : null}
                <IonCardHeader>
                  <IonCardTitle>{userDoc && userDoc.firstName} {userDoc && userDoc.lastName}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                <p className="ion-text-left userNames">{userDoc && userDoc.email}</p>
                <p className="ion-text-left userNames">{userDoc && userDoc.phone}</p>
                <IonButton expand="block" fill="outline" className="ion-margin-vertical">Edit</IonButton>
                </IonCardContent>
              </IonCard>
              
              
            </IonCol>
          </IonRow>
        </IonGrid>     
      </IonContent>
    </IonPage>
  );
};

export default Profile;
