import { useState, useEffect } from "react";
import { useCamera } from '@ionic/react-hooks/camera';
import { useFilesystem, base64FromPath } from '@ionic/react-hooks/filesystem';
import { useStorage } from '@ionic/react-hooks/storage';
import { isPlatform } from '@ionic/react';
import { CameraDirection, CameraSource, CameraResultType, Capacitor, FilesystemDirectory } from "@capacitor/core";
import firebase from '../firebase';
const PHOTO_STORAGE = "photos";


export function usePhotoGallery() {
  const user = firebase.auth().currentUser;
  const [ photos, setPhotos ] = useState([]);
  const { getPhoto } = useCamera();
  const { deleteFile, readFile, writeFile } = useFilesystem();
  const { get, set, clear } = useStorage();

  useEffect(() => {
    const loadSaved = async () => {
      const photosString = await get(PHOTO_STORAGE);
      const photosInStorage = (photosString ? JSON.parse(photosString) : []);
      // If running on the web...
      if (!isPlatform('hybrid')) {
        for (let photo of photosInStorage) {
          const file = await readFile({
            path: photo.filepath,
            directory: FilesystemDirectory.Data
          });
          // Web platform only: Save the photo into the base64 field
          photo.base64 = `data:image/jpeg;base64,${file.data}`;
        }
      }
      setPhotos(photosInStorage);
    };
    loadSaved();
  }, [get, readFile]);
  
  const clearPhotos = () => {
    clear(PHOTO_STORAGE);
    const photosInStorage = ([]);
    setPhotos(photosInStorage);
  }
  
  const takePhoto = async () => {

      const cameraPhoto = await getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        allowEditing: true,
        direction: CameraDirection.Front,
        quality: 70,
        width: 1000,
        height: 1000,
        preserveAspectRatio: true,
      })
      const fileName = new Date().getTime() + '.jpeg';
      const savedFileImage = await savePicture(cameraPhoto, fileName);
      const newPhotos = [savedFileImage, ...photos];
      
      setPhotos(newPhotos);
      
      set(PHOTO_STORAGE,
        isPlatform('hybrid')
          ? JSON.stringify(newPhotos)
          : JSON.stringify(newPhotos.map(p => {
            // Don't save the base64 representation of the photo data, 
            // since it's already saved on the Filesystem
            const photoCopy = { ...p };
            // delete photoCopy.base64;
            return photoCopy;
          })));

  };
  
  const savePicture = async (photo, fileName) => {
   
    let base64Data;
    // "hybrid" will detect Cordova or Capacitor;
    if (isPlatform('hybrid')) {
      const file = await readFile({
        path: photo.path
      });
      base64Data = file.data;
    } else {
      base64Data = await base64FromPath(photo.webPath);
    }
    const savedFile = await writeFile({
      path: fileName,
      data: base64Data,
      directory: FilesystemDirectory.Data
    });
    
    const b64 = base64FromPath(photo.webPath);

    uploadImage(b64, fileName);

    if (isPlatform('hybrid')) {
      // Display the new image by rewriting the 'file://' path to HTTP
      // Details: https://ionicframework.com/docs/building/webview#file-protocol
      return {
        filepath: savedFile.uri,
        webviewPath: Capacitor.convertFileSrc(savedFile.uri),
      };
    }
    else {
      // Use webPath to display the new image instead of base64 since it's 
      // already loaded into memory
      return {
        filepath: fileName,
        webviewPath: photo.webPath
      };
    }
  };
  
  const uploadImage = (b64, fileName) => {
    
    const storageRef = firebase.storage().ref(`images/${user.email}/${fileName}`);
    
    b64.then((image) => {
      storageRef.putString(image, 'data_url')
      .then(function(snapshot) {
        console.log('Uploaded a b64! to', snapshot);
        storageRef.getDownloadURL().then(function(url) {
          console.log(url);
    
          // Now you have valid `imageURL` from async call
          // var user = auth.currentUser;
          user.updateProfile({ photoURL: url })
            .then(function() { console.log(user) })
            .catch(function(error) { console.log(error) });
        })
        .catch(function(error) { console.log(error) }); 
      })
      .catch(err => console.log(err));
       
    })
  };
  const takeMaskie = async () => {

    const cameraPhoto = await getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      allowEditing: true,
      direction: CameraDirection.Front,
      quality: 70,
      width: 1000,
      height: 1000,
      preserveAspectRatio: false,
    })
    const fileName = new Date().getTime() + '.jpeg';
    const savedFileImage = await saveMaskie(cameraPhoto, fileName);
    const newPhotos = [savedFileImage, ...photos];
    
    setPhotos(newPhotos);
    
    set(PHOTO_STORAGE,
      isPlatform('hybrid')
        ? JSON.stringify(newPhotos)
        : JSON.stringify(newPhotos.map(p => {
          // Don't save the base64 representation of the photo data, 
          // since it's already saved on the Filesystem
          const photoCopy = { ...p };
          // delete photoCopy.base64;
          return photoCopy;
        })));
  };
  const saveMaskie = async (photo, fileName) => {
   
    let base64Data;
    // "hybrid" will detect Cordova or Capacitor;
    if (isPlatform('hybrid')) {
      const file = await readFile({
        path: photo.path
      });
      base64Data = file.data;
    } else {
      base64Data = await base64FromPath(photo.webPath);
    }
    const savedFile = await writeFile({
      path: fileName,
      data: base64Data,
      directory: FilesystemDirectory.Data
    });
    
    // const b64 = base64FromPath(photo.webPath);

    // uploadMaskie(b64, fileName);

    if (isPlatform('hybrid')) {
      // Display the new image by rewriting the 'file://' path to HTTP
      // Details: https://ionicframework.com/docs/building/webview#file-protocol
      return {
        filepath: savedFile.uri,
        webviewPath: Capacitor.convertFileSrc(savedFile.uri),
      };
    }
    else {
      // Use webPath to display the new image instead of base64 since it's 
      // already loaded into memory
      return {
        filepath: fileName,
        webviewPath: photo.webPath
      };
    }
  };
  const uploadMaskie = (b64, fileName) => {
    
    const storageRef = firebase.storage().ref(`images/${user.email}/dependents/${fileName}`);
    
    b64.then((image) => {
      storageRef.putString(image, 'data_url')
      .then(function(snapshot) {
        console.log('Uploaded a b64! to', snapshot);
        storageRef.getDownloadURL().then(function(url) {
          console.log(url);
    
          // Now you have valid `imageURL` from async call
          // var user = auth.currentUser;
        })
        .catch(function(error) { console.log(error) }); 
      })
      .catch(err => console.log(err));
       
    })
  };

  const deletePhoto = async (photo) => {
    const storageRef = firebase.storage().ref(`images/${user.email}/${photo.filepath}`);
    // console.log(photo);
    
    // Remove this photo from the Photos reference data array
    const newPhotos = photos.filter(p => p.filepath !== photo.filepath);
    storageRef.delete().then(function() {
      console.log(photo, 'deleted')
    }).catch(function(error) {
      console.log(error)
    });
    // Update photos array cache by overwriting the existing photo array
    set(PHOTO_STORAGE, JSON.stringify(newPhotos));

    // delete photo file from filesystem
    const filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1);
    await deleteFile({
      path: filename,
      directory: FilesystemDirectory.Data
    });
    setPhotos(newPhotos);
  };

  return {
    deletePhoto,
    photos,
    takePhoto,
    takeMaskie,
    clearPhotos,
  };
}
