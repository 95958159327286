import React from 'react';
// import { useHistory } from 'react-router';
import { IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Tab1.css';
import { auth } from '../firebase';

const More = () => {
  const logout = () => {
    auth.signOut();
    window.location.reload();
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>More</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">More</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <IonItem color="light" detail routerLink={'/more/profile'} routerDirection="forward" lines="none">
            <IonLabel>My Profile</IonLabel>
          </IonItem>
          <IonItem color="light" detail routerLink={'/more/dependents'} routerDirection="forward" lines="none">
            <IonLabel>My Dependents</IonLabel>
          </IonItem>
          <IonItem color="light" detail routerLink={'/more/groups'} routerDirection="forward" lines="none">
            <IonLabel>Groups</IonLabel>
          </IonItem>
          <IonItem color="light" onClick={logout} lines="none">
            <IonLabel>Log Out</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default More;
