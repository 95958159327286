import React, { useEffect, useState } from 'react';
import { IonLoading, IonContent, IonHeader, IonPage, IonToolbar, IonTitle, IonCard,IonCardContent,IonCardHeader,IonCardTitle,IonCardSubtitle, IonBackButton, IonButtons } from '@ionic/react';
import './Tab1.css';
import firebaseApp from '../firebase';

const firestore = firebaseApp.firestore();

const Facilities = (props) => {
  const [ location ] = useState(props.match.params.id ? props.match.params.id : null)
  const [ showLoading, setShowLoading ] = useState(false);
  const [ facilities, setFacilities ] = useState(null);

  const getFacilities = () => {
    setShowLoading(true);
    
    firestore.collection('municipalities').doc(location).collection('facilities')
      .get()
      .then((querySnapshot) => {
        const facs = []
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const id = doc.id;
            // console.log(id, " => ", data);
            facs.push({id, ...data})
        });
        const sortedFacs = facs.sort(function(a, b){
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
        })
        setFacilities(sortedFacs)
        setShowLoading(false);
      })
      .catch((err) => console.log('1: ', err))
    
  }
  useEffect(() => {
    getFacilities();
  }, [])
  
  // useEffect(() => {
  //   console.log(location);
  // }, [])


  return (
    <IonPage className="gradient">
      <IonLoading isOpen={showLoading}/>
        <IonHeader translucent="true">
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text="Nearby" />
            </IonButtons>
            <IonTitle>{location}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader translucent="true" collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{location}</IonTitle>
            </IonToolbar>
          </IonHeader>
          {facilities && facilities.map((fac, i) => (
              <IonCard
                button="true" 
                key={i} 
                onClick={() => {
                  props.history.push({
                  pathname: `${location}/${fac.name}`,
                  state: { facId: fac.id }
                })
              }} 
              >
                <img src={fac.photo} alt={fac.name} />
                <IonCardHeader>
                  <IonCardTitle>{fac.name}</IonCardTitle>
                  <IonCardSubtitle>{fac.address}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent></IonCardContent>
              </IonCard>
            ))
          }
        </IonContent>
    </IonPage>
  )
};

export default Facilities;
