import React, { useEffect, useState } from 'react';
import { IonLoading, IonContent, IonHeader, IonPage, IonToolbar, IonTitle, IonCard,IonCardContent,IonCardHeader,IonCardTitle,IonCardSubtitle, IonButtons, IonBackButton, IonGrid, IonRow, IonCol, IonList, IonListHeader, IonItem, IonLabel, IonDatetime, IonToggle, IonButton } from '@ionic/react';
import './Tab1.css';
import firebaseApp from '../firebase';
import dayjs from 'dayjs';
import { useAuthState } from 'react-firebase-hooks/auth';

const firestore = firebaseApp.firestore();

const Facility = (props) => {
  const [ location, setLocation ] = useState(props.match.params.id ? props.match.params.id : null);
  const [ showLoading, setShowLoading ] = useState(false);
  const [ facility, setFacility ] = useState(props.match.params.name ? props.match.params.name : null);
  const [ details, setDetails ] = useState(null);
  const now = dayjs(Date.now());
  const [ selectedDate, setSelectedDate ] = useState(now);
  const [ reminder, setReminder ] = useState(false);
  const [ user ] = useAuthState(firebaseApp.auth());
  // console.log(now.format())
  const getFacility = () => {
    setShowLoading(true);
    firestore.collection('municipalities').doc(location).collection('facilities').doc(props.location.state.facId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        setDetails(doc.data())
        // console.log(doc.data())
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    })
    .catch((err) => {
      console.log(err)
    })
    setShowLoading(false);
  }
  const createReminder = () => {
    setShowLoading(true);

    firestore.collection('users').doc(user.uid).collection('reminders').add({
      location: location,
      facility: facility,
      date: selectedDate,
      reminder: reminder,
      address: details.address,
      photo: details.photo,
      link: details.link
    })
    .then(res => {
      console.log(res);
      setShowLoading(false);
      props.history.push('/plan')
    })
    .catch((err) => {
      console.log(err);
      setShowLoading(false);
      alert(err);
    })
  }
  useEffect(() => {
    getFacility();
  }, [])
  // useEffect(() => {
  //   console.log(props);
  // }, [])

  return (
    <IonPage className="gradient">
      <IonLoading isOpen={showLoading}/>
        <IonHeader translucent="true">
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text={location} />
            </IonButtons>
            <IonTitle>{facility}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader style={{backgroundImage: `url(${details && details.photo})`}} collapse="condense">
            <IonToolbar className="customTitle">
              <IonCardTitle size="large">{facility}</IonCardTitle>
              <IonCardSubtitle>{details && details.address}, {location}</IonCardSubtitle>
            </IonToolbar>
          </IonHeader>
          {facility ?
            <div className="ion-padding-vertical">
              <img src={details && details.photo} alt={details && details.name} />
            </div>
          : null}
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonCard>
                <IonList>
                  <IonListHeader>Plan a visit</IonListHeader>
                  <IonItem>
                    <IonLabel>When?</IonLabel>
                    <IonDatetime 
                      displayFormat="MMM DD YYYY, hh:mm a" 
                      placeholder="Select Date" 
                      value={selectedDate} 
                      onIonChange={e => setSelectedDate(e.detail.value)}
                      min={now.format('YYYY-MM-DD')}
                      max={'2021-12-31'}
                      minuteValues="0,15,30,45"  
                      />
                  </IonItem>
                  <IonItem>
                    <IonLabel>Alert me?</IonLabel>
                    <IonToggle checked={reminder} onIonChange={() => setReminder(!reminder)} />
                  </IonItem>
                  <div style={{ height: 25}} />
                  <IonButton expand="block" className="ion-margin-horizontal" onClick={() => createReminder()}>Plan it!</IonButton>
                  <div style={{ height: 25}} />
                </IonList>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
    </IonPage>
  )
};

export default Facility;
