import firebase from "firebase/app";
import 'firebase/auth';        // for authentication
import 'firebase/firestore';   // for cloud firestore
import 'firebase/functions';   // for cloud functions
import { toast } from './components/Toast/Toast';

const firebaseConfig = {
  apiKey: "AIzaSyDIvo0aYbc9AUOcggekWOvyFoeNoVItCF8",
  authDomain: "town-pass.firebaseapp.com",
  databaseURL: "https://town-pass.firebaseio.com",
  projectId: "town-pass",
  storageBucket: "town-pass.appspot.com",
  messagingSenderId: "983154829726",
  appId: "1:983154829726:web:09757ea25b4885011dce8f",
  measurementId: "G-WEF16TLB00"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.functions();
firebase.firestore();
export default firebaseApp;
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export async function loginUser(email, password) {
  try {
    const res = await firebase.auth().signInWithEmailAndPassword(email, password)
    console.log(res)
    return true
  } catch(error) {
    toast(error.message, 4000)
    return false
  }
}
export function logoutUser() {
  return firebase.auth().signOut()
}

