import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, 
  IonCol, IonContent, IonGrid, IonIcon, IonRow, IonPage, IonHeader, IonToolbar, IonTitle } from '@ionic/react';
import { mailOpenOutline } from 'ionicons/icons';
import React from 'react';
// import { useHistory } from 'react-router';
import './ExploreContainer.css';

const OnBoardingContainer = (props) => {
  const { user } = props 

  return (
    <IonPage>
      <IonHeader translucent="true">
      <IonToolbar>
        <IonTitle>Verify Email</IonTitle>
      </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Verify Email <IonIcon icon={mailOpenOutline} className="titleIcon" /></IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol className="ion-padding">
              <IonCard>
                <IonCardHeader className="ion-padding">
                  <IonCardTitle className="ion-text-center">Please check your email...</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                <p className="ion-text-center">We have sent an email with a confirmation link to {user.email}. Please click the link to verify your address then come back to this page to continue.</p>
              <p className="ion-text-center">Thank you!</p>
              <p className="ion-text-center">The TownPass Team</p>
              <p className="ion-text-center ion-padding-vertical"><IonButton className="ion-margin-top" fill="outline" color="primary" onClick={() => window.location.reload()}>Continue</IonButton></p>
              <p className="ion-text-center">Please allow up to 10 minutes for the verification email to arrive. If you still don't see it, please check your "Spam" or "Junk" folders.</p>
                </IonCardContent>
              </IonCard>
              
              
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default OnBoardingContainer;
