import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg, IonFabButton, IonActionSheet,
  IonCol, IonContent, IonGrid, IonIcon, IonRow, IonHeader, IonToolbar, IonTitle, IonList, IonItem, IonLabel, IonInput, IonLoading } from '@ionic/react';
import { happyOutline } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router';
import './ExploreContainer.css';
import { camera, trash, close } from 'ionicons/icons';

import { usePhotoGallery } from '../hooks/usePhotoGallery';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import maskie from '../assets/images/maskie.jpg';
import firebaseApp from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const firestore = firebaseApp.firestore();

const OnBoardingContainer = () => {
  const [ step, setStep ] = useState(0);
  const [ user ] = useAuthState(firebaseApp.auth());
  const [ isLoading, setIsLoading ] = useState(false);
  const { deletePhoto, photos, takePhoto } = usePhotoGallery();
  const [ photoToDelete, setPhotoToDelete ] = useState();
  const [ userDetails, setUserDetails ] = useState({
    firstName: null,
    lastName: null,
    phone: null,
  })

  const getStarted = () => {
    setStep(1)
  }
  const saveUser = () => {
    firestore.collection('users').doc(user.uid).update({
      completed: true,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      phone: '+'+userDetails.phone,
    })
    .then(function() {
      console.log('updated user');
      setIsLoading(true);
      setTimeout(() => {
        window.location.reload()
      }, 1500);
    })
    .catch(function(error) {
      console.log('update user failed', error)
    });
  }

  useEffect(() => {
    // console.log(step);
    if (step === 6) {
      
    }
  }, [step])


  return (
    <>
      <IonLoading isOpen={isLoading} />
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonTitle>{step === 0 ? 'Welcome' : 'Step ' + step}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader translucent={true} collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{step === 0 ? 'Welcome ' : step <= 5 ? 'Step ' + step : null} {step === 0 ? (<IonIcon icon={happyOutline} className="titleIcon"/>) : null}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard>
                <div className={step === 0 ? null : 'ion-hide'}>
                  <IonCardHeader className="ion-padding">
                    <IonCardTitle className="ion-text-center">Just this once...</IonCardTitle>
                    <IonCardSubtitle className="ion-text-center">We have a bit more work to do.</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                  <p className="ion-padding-vertical ion-text-center">Facilities need to do contact tracing, but we also want to help you not have to fill out forms all day. To do so, we need to get some basic information <strong>just this once</strong>.</p>
                    <br/>
                    <p className="ion-padding-bottom ion-text-center"><IonButton expand="block" onClick={getStarted}>Get Started!</IonButton></p>
                  </IonCardContent>
                </div>
                <div className={step === 1 ? null : 'ion-hide'}>
                  <IonCardHeader className="ion-padding">
                    <IonCardSubtitle className="ion-text-center">Getting to know you...</IonCardSubtitle>
                    <IonCardTitle className="ion-text-center">First name?</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                        <IonLabel position="floating">Please enter here</IonLabel>
                        <IonInput autocapitalize="on" autofocus="true" value={userDetails.firstName} onIonChange={(e) => setUserDetails({...userDetails, firstName: e.detail.value})}/>
                      </IonItem>
                    </IonList>
                    {userDetails.firstName ? 
                      <p className="ion-padding-vertical ion-text-center"><IonButton expand="block" onClick={() => setStep(2)}>Next</IonButton></p>
                    : null }
                  </IonCardContent>
                </div>
                <div className={step === 2 ? null : 'ion-hide'}>
                  <IonCardHeader className="ion-padding">
                    <IonCardSubtitle className="ion-text-center">Getting to know you...</IonCardSubtitle>
                    <IonCardTitle className="ion-text-center">Last name?</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                        <IonLabel position="floating">Please enter here</IonLabel>
                        <IonInput autocapitalize="on" autofocus="true" value={userDetails.lastName} onIonChange={(e) => setUserDetails({...userDetails, lastName: e.detail.value})}/>
                      </IonItem>
                    </IonList>
                    {userDetails.lastName ? 
                      <p className="ion-padding-vertical ion-text-center"><IonButton expand="block" onClick={() => setStep(3)}>Next</IonButton></p>
                    : null }
                  </IonCardContent>
                </div>
                <div className={step === 3 ? null : 'ion-hide'}>
                  <IonCardHeader className="ion-padding">
                    <IonCardSubtitle className="ion-text-center">Getting to know you...</IonCardSubtitle>
                    <IonCardTitle className="ion-text-center">Phone Number?</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList>
                      <IonItem lines="none">
                        <IonLabel position="stacked">Please enter here</IonLabel>
                        <PhoneInput
                          country={'ca'}
                          value={userDetails.phone}
                          onChange={phone => setUserDetails({...userDetails, phone: phone })}
                          inputClass="phoneInput"
                        />
                        {/* <IonInput value={userDetails.phone} onIonChange={(e) => setUserDetails({...userDetails, phone: e.detail.value})}/> */}
                      </IonItem>
                    </IonList>
                    {userDetails.phone && userDetails.phone.length >= 11 ? 
                      <p className="ion-padding-vertical ion-text-center"><IonButton expand="block" onClick={() => setStep(4)}>Next</IonButton></p>
                    : null }
                    <p className="ion-text-center ion-padding-vertical"><strong>Why do we need your phone number?</strong></p>
                    <p className="ion-text-center ion-padding-bottom">We only use your phone number if we need to alert you to any changes to your TownPass, a facility, or we need to alert you to a COVID-19 exposure.</p>
                  </IonCardContent>
                </div>
                <div className={step === 4 ? null : 'ion-hide'}>
                  {photos.length > 0 ? 
                  <div className="">
                    <IonImg className="maskie" onClick={() => setPhotoToDelete(photos[0])} src={photos[0].base64 ?? photos[0].webviewPath} />
                    <IonFabButton color="danger" className="picBtn" style={{margin: '0 auto'}} onClick={() => setPhotoToDelete(photos[0])}>
                      <IonIcon icon={trash}></IonIcon>
                    </IonFabButton>
                  </div>
                  :
                  <div className="maskieArea">
                    <img alt="maskie" src={maskie} />
                    <IonFabButton className="picBtn" disabled={photos.length > 0 ? true : false} style={{margin: '0 auto'}} onClick={() => takePhoto()}>
                      <IonIcon icon={camera}></IonIcon>
                    </IonFabButton>
                  </div>
                  }
                  <IonCardHeader className="ion-padding">
                    <IonCardTitle className="ion-text-center">Maskie</IonCardTitle>
                    <IonCardSubtitle className="ion-text-center">Maskie | 'mahski | noun</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p className="ion-text-center">A maskie is like a Selfie, but with your favourite mask on!</p>
                    <p className="ion-text-center ion-padding-vertical"><strong>Why a Maskie?</strong></p>
                    <p className="ion-text-center ion-padding-bottom">By adding a Maskie, you'll help the facility staff confirm more quickly that the person under the mask is the same person that completed a TownPass for that day. It will also help you quickly pull up the correct TownPass if you also have dependents in your profile.</p>
                    <p className="ion-text-center">While useful, a Maskie is completely optional. </p>
                   
                    <IonGrid>
                      <IonRow>
                        <IonCol>

                          <p className="ion-text-center">
                          {photos.length < 1 ?
                            <IonButton expand="block" onClick={() => takePhoto()} color="primary"><IonIcon icon={camera}></IonIcon> &nbsp; Take Maskie</IonButton>
                          :
                            <IonButton expand="block" onClick={() => setStep(5)} color="primary">Next</IonButton>
                          }
                          </p>
                        </IonCol>
                        <IonCol>
                          <p className="ion-text-center"><IonButton expand="block" fill="clear" color="secondary" onClick={() => setStep(5)}>Skip</IonButton></p>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </div>
                <div className={step === 5 ? null : 'ion-hide'}>
                  {photos.length > 0 ? 
                  <div className="">
                    <IonImg className="maskie" src={photos[0].base64 ?? photos[0].webviewPath} />
                  </div>
                  :
                  null
                  }
                  <IonCardHeader className="ion-padding">
                    <IonCardSubtitle className="ion-text-center">Please confirm</IonCardSubtitle>
                    <IonCardTitle className="ion-text-center">Your Details</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p className="ion-text-center">{userDetails.firstName} {userDetails.lastName}</p>
                    <p className="ion-text-center">{userDetails.phone}</p>
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <p className="ion-text-center"><IonButton expand="block" fill="solid" color="primary" onClick={saveUser}>Confirm</IonButton></p>
                        </IonCol>
                        <IonCol>
                          <p className="ion-text-center"><IonButton expand="block" fill="outline" color="secondary" onClick={() => setStep(1)}>Go Back</IonButton></p>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </div>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonActionSheet
          isOpen={!!photoToDelete}
          buttons={[{
            text: 'Delete Maskie',
            role: 'destructive',
            icon: trash,
            handler: () => {
              if (photoToDelete) {
                deletePhoto(photoToDelete);
                setPhotoToDelete(undefined);
              }
            }
          }, {
            text: 'Cancel',
            icon: close,
            role: 'cancel'
          }]}
          onDidDismiss={() => setPhotoToDelete(undefined)}
        />
        
      </IonContent>
    </>
  );
};

export default OnBoardingContainer;
