import React, { useEffect, useState } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, 
  IonPage, IonRow, IonTitle, IonToolbar, IonButton, IonLoading, IonButtons, IonBackButton, IonCardSubtitle, IonSearchbar, IonSpinner } from '@ionic/react';
import { UserStore } from '../store/UserStore';
import './Tab1.css';
import firebaseApp from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const Groups = () => {
  const [ user ] = useAuthState(firebaseApp.auth());
  const [ groups, setGroups ] = useState(null);
  const [ groupsList, setGroupsList ] = useState(groups);
  const [ searchText, setSearchText ] = useState();
  const [ showLoading, setShowLoading ] = useState(false);
  const userDoc = UserStore.useState(s => s.userDoc);
  
  const handleSearchChange = value => {
    // console.log(value);
    setSearchText(value);
    filterData(value);
  };
  const filterData = (value) => {
    setShowLoading(true);
    // console.log(value);
    if (groups) {
      const lowercasedValue = value.toLowerCase().trim();
      // console.log(lowercasedValue);
      if (lowercasedValue === "") {
        setGroupsList(groups);
        setShowLoading(false);
        // console.log('lowercasedValue ===', lowercasedValue)
      }
      else {
        // console.log('lowercasedValue ===', lowercasedValue)
        const filteredData = groups.filter(item => {
          return Object.keys(item).some(key =>
            item[key] ? item[key].toString().toLowerCase().includes(lowercasedValue) : false
          );
        });
        setGroupsList(filteredData);
        setShowLoading(false);
        // console.log(filteredData)
      }
    }
  }
  useEffect(() => {
    console.log(userDoc);
    console.log(user.photoURL);
    firebaseApp.firestore().collection('groups')
    .get()
    .then((querySnapshot) => {
      const groupsList = [];
      querySnapshot.forEach((doc) => {
        console.log(doc.id)
        const data = doc.data();
        const id = doc.id;
        groupsList.push({id, ...data})
      })
      setGroups(groupsList);
    })
    .catch((error) => console.log(error));
  }, [userDoc])
  return (
    <IonPage>
      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Please wait...'}
        duration={1000}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="More" />
          </IonButtons>
          <IonTitle>Groups</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Groups</IonTitle>
          </IonToolbar>
          <IonToolbar className="pad-top">
            <IonSearchbar 
              mode="ios" 
              animated 
              placeholder="Search" 
              debounce={1000} 
              value={searchText} 
              onIonChange={e => handleSearchChange(e.detail.value)} 
              showCancelButton="focus"
            />
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            {groupsList ? groupsList.map((group, i) => (
              <IonCol sizeXs="12" sizeMd="4" key={i}>
                <IonCard className="groupCard" button routerLink={`/more/groups/${group.id}`}>
                  <div className="logoHolder">
                    <img className="groupLogo" src={group.logoUrl} alt="logo" />
                  </div>
                  <IonCardHeader>
                    <IonCardSubtitle>{group.type}</IonCardSubtitle>
                    <IonCardTitle>{group.name}</IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              </IonCol>
              ))
            : 
              <IonCol style={{ 
                margin: '0 auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'}}>
                <IonSpinner
                 name="crescent"
                 style={{marginTop: '50%'}} />
              </IonCol>
            }
          </IonRow>
        </IonGrid>     
      </IonContent>
    </IonPage>
  );
};

export default Groups;
