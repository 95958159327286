import React, { useEffect, useState, useRef } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol,
  IonContent, IonGrid, IonHeader, IonModal, IonList, IonItem, IonPage, IonRow, IonTitle, 
  IonToolbar, IonButton, IonLoading, IonButtons, IonAvatar, IonLabel, IonInput, IonIcon, IonAlert, IonImg, IonFabButton, IonActionSheet, IonBackButton } from '@ionic/react';
import './Tab1.css';
import firebaseApp from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { cameraOutline, close, closeCircle, trash } from 'ionicons/icons';
import { useCamera } from '@ionic/react-hooks/camera';
import { useFilesystem, base64FromPath } from '@ionic/react-hooks/filesystem';
import { useStorage } from '@ionic/react-hooks/storage';
import { isPlatform } from '@ionic/react';
import { CameraDirection, CameraSource, CameraResultType, Capacitor, FilesystemDirectory } from "@capacitor/core";
const PHOTO_STORAGE = "photos";

const Dependents = () => {
  const pageRef = useRef();
  const { getPhoto } = useCamera();
  const { deleteFile, readFile, writeFile } = useFilesystem();
  const { get, set, clear } = useStorage();
  const [ photos, setPhotos ] = useState([]);
  const [ photoToDelete, setPhotoToDelete ] = useState();
  const [ user ] = useAuthState(firebaseApp.auth());
  const [ isLoading, setIsLoading ] = useState(true);
  const [ dependents, setDependents ] = useState(null);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ currentDependent, setCurrentDependent ] = useState({firstName: null, lastName: null, age: null, maskie: null});
  const [ ready, setReady ] = useState(false);
  const [ showAlert, setShowAlert ] = useState(false);

  const getDependents = () => {
    firebaseApp.firestore().collection('users').doc(user.uid).collection('dependents')
    .get()
    .then(function(querySnapshot) {
      // console.log(querySnapshot.docs)
      const dependentsList = [];
      if (querySnapshot.docs.length < 1) {
        setDependents(null);
      }
      else {
        querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            const id = doc.id;
            const data = doc.data();
            dependentsList.push({id, ...data})
        });
      }
      setDependents(dependentsList.length < 1 ? null : dependentsList);
      setIsLoading(false);
    });  
  }
  const createDependent = () => {
    console.log(currentDependent);
    firebaseApp.firestore().collection('users').doc(user.uid).collection('dependents').add({
      firstName: currentDependent.firstName,
      lastName: currentDependent.lastName,
      age: currentDependent.age,
      maskie: currentDependent.maskie
    })
    .then((res) => console.log(res))
    .catch((err) => console.log(err));
    getDependents();
    setCurrentDependent({firstName: null, lastName: null, age: null, maskie: null});
    setPhotos([]);
    setIsOpen(false);
  }

  const deleteDependent = () => {
    setIsLoading(true);
    console.log(currentDependent);
    firebaseApp.firestore().collection('users').doc(user.uid).collection('dependents').doc(currentDependent.id)
    .delete()
    .then((res) => {
      getDependents();
      console.log(res)
    })
    .catch((err) => console.log(err));
    
    setIsLoading(false);
  }
  const takeMaskie = async () => {

    const cameraPhoto = await getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      allowEditing: true,
      direction: CameraDirection.Front,
      quality: 70,
      width: 1000,
      height: 1000,
      preserveAspectRatio: false,
    })
    const fileName = new Date().getTime() + '.jpeg';
    const savedFileImage = await saveMaskie(cameraPhoto, fileName);
    const newPhotos = [savedFileImage, ...photos];
    
    setPhotos(newPhotos);
    
    set(PHOTO_STORAGE,
      isPlatform('hybrid')
        ? JSON.stringify(newPhotos)
        : JSON.stringify(newPhotos.map(p => {
          // Don't save the base64 representation of the photo data, 
          // since it's already saved on the Filesystem
          const photoCopy = { ...p };
          // delete photoCopy.base64;
          return photoCopy;
        })));
  };
  const saveMaskie = async (photo, fileName) => {
   
    let base64Data;
    // "hybrid" will detect Cordova or Capacitor;
    if (isPlatform('hybrid')) {
      const file = await readFile({
        path: photo.path
      });
      base64Data = file.data;
    } else {
      base64Data = await base64FromPath(photo.webPath);
    }
    const savedFile = await writeFile({
      path: fileName,
      data: base64Data,
      directory: FilesystemDirectory.Data
    });
    
    const b64 = base64FromPath(photo.webPath);

    uploadMaskie(b64, fileName);

    if (isPlatform('hybrid')) {
      // Display the new image by rewriting the 'file://' path to HTTP
      // Details: https://ionicframework.com/docs/building/webview#file-protocol
      return {
        filepath: savedFile.uri,
        webviewPath: Capacitor.convertFileSrc(savedFile.uri),
      };
    }
    else {
      // Use webPath to display the new image instead of base64 since it's 
      // already loaded into memory
      return {
        filepath: fileName,
        webviewPath: photo.webPath
      };
    }
  };
  const uploadMaskie = (b64, fileName) => {
    
    const storageRef = firebaseApp.storage().ref(`images/${user.email}/dependents/${fileName}`);
    
    b64.then((image) => {
      storageRef.putString(image, 'data_url')
      .then(function(snapshot) {
        console.log('Uploaded a b64! to', snapshot);
        storageRef.getDownloadURL().then(function(url) {
          console.log(url);
    
          setCurrentDependent({...currentDependent, maskie: url})
        })
        .catch(function(error) { console.log(error) }); 
      })
      .catch(err => console.log(err));
       
    })
  };

  const deleteMaskie = async (photo) => {
    const storageRef = firebaseApp.storage().ref(`images/${user.email}/dependents/${photo.filepath}`);
    // console.log(photo);
    
    // Remove this photo from the Photos reference data array
    const newPhotos = photos.filter(p => p.filepath !== photo.filepath);
    storageRef.delete().then(function() {
      console.log(photo, 'deleted')
    }).catch(function(error) {
      console.log(error)
    });
    // Update photos array cache by overwriting the existing photo array
    set(PHOTO_STORAGE, JSON.stringify(newPhotos));

    // delete photo file from filesystem
    const filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1);
    await deleteFile({
      path: filename,
      directory: FilesystemDirectory.Data
    });
    setPhotos(newPhotos);
  };
  useEffect(() => {
    getDependents()
  }, [])

  useEffect(() => {
    // console.log(currentDependent);
    if (currentDependent.age && currentDependent.firstName && currentDependent.lastName) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [currentDependent])
  return (
    <IonPage ref={pageRef}>
    <IonLoading isOpen={isLoading}/>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Dependents</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Dependents</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard className="depCard">
                <IonCardHeader>
                  <IonCardSubtitle>{!dependents ? 'Add your' : user.displayName + "'s"}</IonCardSubtitle>
                  <IonCardTitle>Dependent{dependents && dependents.length <= 1 ? null : 's'}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                {dependents === null ? 
                  <p>You can add your dependents here so that you'll be able to scan their TownPasses on their behalf when you arrive at participating facilities.</p> 
                : 
                <IonList className="depList">
                  {dependents && dependents.map((dep, i) => (
                    <IonItem lines="none" key={i} className="ion-no-padding">
                      <IonAvatar slot="start">
                        {dep.maskie? 
                        <img src={dep.maskie} alt="user" />
                        :
                        <img src="https://via.placeholder.com/150" alt="user" />
                        }
                      </IonAvatar>
                      <IonLabel>{dep.firstName + ' ' + dep.lastName}</IonLabel>
                      {/* <IonLabel>{dep.id}</IonLabel> */}
                      <IonIcon icon={closeCircle} slot="end" onClick={() => {
                        setCurrentDependent(dep);
                          setShowAlert(true);
                        }}/>
                    </IonItem>
                  ))}
                </IonList>
                }
                <IonButton expand="block" fill="outline" className="addIt" onClick={() => setIsOpen(!isOpen)}>Add</IonButton>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid> 
        <IonModal
          swipeToClose
          mode="ios"
          presentingElement={pageRef.current}
          isOpen={isOpen}
          onDidDismiss={() => setIsOpen(false)}
          cssClass='my-custom-class'
          >
          <IonHeader translucent>
            <IonToolbar>
              <IonTitle>Add Dependent</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="ion-padding">
            <IonGrid>
              <IonRow>
                <IonCol className="ion-padding-horizontal">
                  <p className="ion-padding-vertical">Please fill in the details for the dependent below. You can add another dependent after, if necessary.</p>

                  {photos.length > 0 ? 
                  <>
                    <div className="maskiePlaceholder">
                      <div className="maskieImg" style={{backgroundImage: `url(${photos[0].base64 ?? photos[0].webviewPath})`}} onClick={() => setPhotoToDelete(photos[0])}></div>
                      <p className="ion-text-center"><IonIcon icon={trash} color="danger" onClick={() => setPhotoToDelete(photos[0])} /></p>
                    </div>
                  </>
                  : 
                    <div className="maskiePlaceholder">
                      <div className="maskie"><IonIcon className="cam" icon={cameraOutline} onClick={() => takeMaskie()}/></div>
                      <p className="ion-text-center">Take a Maskie</p>
                    </div>
                  }
                  <IonList>
                    <IonItem>
                      <IonLabel position="floating">First name</IonLabel>
                      <IonInput type="text" autocapitalize="on" onIonChange={(e) => setCurrentDependent({...currentDependent, firstName: e.target.value})} />
                    </IonItem>
                    <IonItem>
                      <IonLabel position="floating">Last name</IonLabel>
                      <IonInput type="text" autocapitalize="on" onIonChange={(e) => setCurrentDependent({...currentDependent, lastName: e.target.value})} />
                    </IonItem>
                    <IonItem>
                      <IonLabel position="floating">Age</IonLabel>
                      <IonInput type="number" inputmode="numeric" onIonChange={(e) => setCurrentDependent({...currentDependent, age: e.target.value})} />
                    </IonItem>
                  </IonList>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
          <IonButton disabled={!ready} expand="block" onClick={() => createDependent()}>Confirm</IonButton>
        </IonModal>   
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='my-custom-class'
          header={'Alert'}
          subHeader={'Please confirm'}
          message={`Clicking OK will delete ${currentDependent && currentDependent.firstName} from your list of dependents`}
          buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: blah => {
              console.log('Confirm Cancel');
            }
          },
          {
            text: 'OK',
            handler: () => {
              deleteDependent();
            }
          }
        ]}
        /> 
        <IonActionSheet
          isOpen={!!photoToDelete}
          buttons={[{
            text: 'Delete Maskie',
            role: 'destructive',
            icon: trash,
            handler: () => {
              if (photoToDelete) {
                deleteMaskie(photoToDelete);
                setPhotoToDelete(undefined);
              }
            }
          }, {
            text: 'Cancel',
            icon: close,
            role: 'cancel'
          }]}
          onDidDismiss={() => setPhotoToDelete(undefined)}
        />
      </IonContent>
    </IonPage>
  );
};

export default Dependents;
