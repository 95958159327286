import React, { useEffect, useState } from 'react';
import { IonLoading, IonContent, IonHeader, IonPage, IonToolbar, IonTitle, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCardSubtitle, IonSegment, IonSegmentButton, IonItem, IonLabel, IonIcon, IonAvatar } from '@ionic/react';
import './Tab1.css';
import firebaseApp from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import dayjs from 'dayjs';
import { closeCircleOutline, happyOutline, notificationsOffOutline, notificationsOutline } from 'ionicons/icons';

const firestore = firebaseApp.firestore();

const PlanIt = () => {
  const [ locations, setLocations ] = useState(null);
  const [ showLoading, setShowLoading ] = useState(false);
  const [ user ] = useAuthState(firebaseApp.auth());
  const [ segment, setSegment ] = useState('Plan');
  const [ reminders, setReminders ] = useState(null);
  const now = dayjs(Date.now());

  // console.log(now.format())
  
  const getMunicipalities = () => {
    firestore.collection('municipalities').get()
    .then((querySnapshot) => {
      const locs = []
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const id = doc.id;
        locs.push({id, ...data})
      })
      const sortedLocs = locs.sort(function(a, b){
        if(a.id < b.id) { return -1; }
        if(a.id > b.id) { return 1; }
        return 0;
      })
      setLocations(sortedLocs);
      setShowLoading(false);
    })
    .catch(function(error) {
      setShowLoading(false);
      console.log("Error getting documents: ", error);
    });
  }
  const getReminders = () => {
    firestore.collection('users').doc(user.uid).collection('reminders')
    .where("date", ">", now.format())
    .orderBy("date", "asc")
    .get()
    .then((querySnapshot) => {
        const remindersList = []
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          // console.log(doc.id, " => ", doc.data());
          remindersList.push({id, ...data})
        });
        setReminders(remindersList);
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });
  }
  useEffect(() => {
    setShowLoading(true);
    getMunicipalities();
    getReminders();
  }, [])

  // useEffect(() => {
  //   console.log(locations);
  // }, [locations])
  // useEffect(() => {
  //   console.log(reminders);
  // }, [reminders])

  return (
    <IonPage className="gradient">
      <IonLoading isOpen={showLoading}/>
        <IonHeader translucent="true">
          {/* <IonToolbar>
            <IonTitle>Plan</IonTitle>
          </IonToolbar> */}
          <IonToolbar>
            <IonSegment value={segment} onIonChange={e => setSegment(e.detail.value)}>
              <IonSegmentButton value="Plan">Plan it</IonSegmentButton>
              <IonSegmentButton value="Reminders">Reminders</IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
        {segment === 'Plan' ? (
          <>
            <IonHeader translucent="true" collapse="condense">
              <IonToolbar>
                <IonTitle size="large">Nearby</IonTitle>
              </IonToolbar>
            </IonHeader>
            {locations && locations.map((loc, i) => (
              <IonCard button="true" routerLink={`/plan/${loc.id}`} key={i}>
                <img src={loc.photo} alt={loc.id} />
                <IonCardHeader>
                  <IonCardTitle>{loc.type} of {loc.id}</IonCardTitle>
                  <IonCardSubtitle>{loc.address}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent></IonCardContent>
              </IonCard>
            ))}
          </>
          )
          : segment === 'Reminders' ?
            <>
              <IonHeader translucent="true" collapse="condense">
                <IonToolbar>
                  <IonTitle size="large">Reminders</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                {reminders ? reminders.map((rem, i) => (
                  <IonItem key={i}>
                    <IonAvatar slot="start">
                      <div className="ion-text-center dateCircle">{dayjs(rem.date).format('MMM')}<br/>{dayjs(rem.date).format('DD')}</div>
                    </IonAvatar>
                    <IonLabel className="ion-text-wrap">{rem.facility}<br/> {dayjs(rem.date).format('h:mm a')} {rem.reminder ? <IonIcon icon={notificationsOutline} /> : <IonIcon icon={notificationsOffOutline} />}</IonLabel>
                    <IonIcon icon={closeCircleOutline} slot="end" />
                  </IonItem>
                ))
                :
                <div className="ion-padding">
                  <p className="ion-text-center">
                    <IonIcon size="large" icon={happyOutline} />
                  </p>
                  <p className="ion-text-center">
                    You haven't created any reminders, yet. 
                  </p>
                </div>
                }
              </IonContent>
            </>
          : null
        }
        </IonContent>
    </IonPage>
  )
};

export default PlanIt;
