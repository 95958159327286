import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router';
import { IonBackButton, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonPopover, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import dayjs from 'dayjs';
import { getNotifications } from '../App';
import './Tab1.css';

/* Pullstate Store */
import { UserStore } from "../store/UserStore";
import { ellipseSharp, ellipsisHorizontal, mailOpenSharp } from 'ionicons/icons';
import firebaseApp from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const Notifications = () => {
  const notifications = UserStore.useState(s => s.notifications);
  const [ user ] = useAuthState(firebaseApp.auth());
  const notificationsColl = firebaseApp.firestore().collection('users').doc(user.uid).collection('notifications');
  const [ selectedItem, setSelectedItem ] = useState(null);
  const [ showPopover, setShowPopover ] = useState({open: false, event: null});

  const markRead = () => {
    console.log(selectedItem);
    if (selectedItem.unread) {
      notificationsColl.doc(selectedItem.id)
      .update({
        unread: false
      })
      .then(() =>  getNotifications());
    }    
    if (!selectedItem.unread) {
      notificationsColl.doc(selectedItem.id)
      .update({
        unread: true
      })
      .then(() =>  getNotifications());
    } 
  }
  const deleteNotification = (noteid) => {
    console.log(noteid);
    setShowPopover({open: false, event: null});
    notificationsColl.doc(noteid)
      .delete()
      .then(() =>  getNotifications());
  }
  useEffect(() => {
    console.log(notifications);
  }, [])
  useEffect(() => {
    if (selectedItem) {
      markRead();
    }
  }, [ selectedItem ])
  return (
    <IonPage mode="ios">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Notifications</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Notifications</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonList className="roundedList">
                {notifications && notifications.map((note, i) => (
                  <IonItem key={i} color={!note.unread ? "light" : "primary"}>
                    <IonIcon 
                      onClick={() => setSelectedItem(note)} 
                      icon={note.unread ? ellipseSharp : mailOpenSharp} slot="start" 
                      size="small" 
                      className="newNoti" 
                      color={note.unread ? "danger" : "medium"} />
                    <div  onClick={() => setSelectedItem(note)} className="ion-padding">
                    <IonText className="ion-text-wrap notiNote">{note.status}</IonText><br/>
                    <IonText className="dateTime">{dayjs(note.when).format('MMM DD, YYYY. h:mma')}</IonText>
                    </div>
                    <IonButtons slot="end">
                      <IonButton onClick={(e) => setShowPopover({open: true, event: e.nativeEvent})} >
                        <IonIcon slot="icon-only" icon={ellipsisHorizontal} size="small"/>
                      </IonButton>
                      <IonPopover
                        mode="ios"
                        animated
                        translucent="true"
                        showBackdrop={false}
                        backdropDismiss
                        event={showPopover.event}
                        cssClass="ion-padding mapPop"
                        isOpen={showPopover.open}
                        onDidDismiss={e => setShowPopover({open: false, event: null})}
                      >
                        <IonItem 
                          lines="none" 
                          detail 
                          onClick={() => {
                            console.log(note.id)
                            deleteNotification(note.id);
                            }}>
                          <IonLabel className="ion-text-wrap">Delete Notification</IonLabel>
                        </IonItem>
                      </IonPopover>
                    </IonButtons>
                  </IonItem>
                ))}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
        
      </IonContent>
    </IonPage>
  );
};

export default Notifications;
