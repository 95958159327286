import {IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonSpinner, IonModal, IonRow, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, isPlatform} from '@ionic/react';
import { cameraSharp, qrCodeSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import './ExploreContainer.css';
/* Pullstate Store */
import { UserStore } from "../store/UserStore";
import QrReader from 'react-qr-reader';
import QRCode from "react-qr-code";
import firebaseApp from '../firebase';
import dayjs from 'dayjs';
import './InstaScan.css';


const PassHelp = (props) => {
  console.log(props);
  const open = props.open;
  const platform = props.platform;
  const user = firebaseApp.auth().currentUser;
  const [ checking, setChecking ] = useState(false);
  const [ business, setBusiness ] = useState(null);
  const scanData = UserStore.useState(s => s.scanData);
  const ready = UserStore.useState(s => s.instaScan);

  const handleClose = () => {
    UserStore.update(s => {
      s.instaScan = false;
    });
    setChecking(false);
  }
  const clearPass = () => {
    setBusiness(null);
    setChecking(false);
  }

  const firebaseStuff = (data) => {
    firebaseApp.firestore().collection('businesses').doc(data)
    .get()
    .then((doc) => {
        const id = doc.id;
        const docData = doc.data();
        setBusiness({id, ...docData})
    })
    .then(() => {
      firebaseApp.firestore().collection('businesses').doc(data).collection('checkins')
      .doc(dayjs().format('MMM DD, YYYY. h:mm:ssa'))
      .set({
        user: user.uid,
        when: dayjs().format('MMM DD, YYYY. h:mm:ssa')
      })
    })
    .catch((error) => console.log('error => ', error))
  }
  const handleScan = data => {
    if (data) {
      setChecking(true);
      firebaseStuff(data);
      setChecking(false);
    }
  }
  const handleError = err => {
    console.error(err)
  }
  useEffect(() => {
    console.log(platform);
    if (platform === 'native' && scanData) {
      firebaseStuff(scanData)
    }
  }, [platform]);

  return (
    <>
    <IonModal
      isOpen={open}
      cssClass='scanModal'
      swipeToClose={true}
      onDidDismiss={handleClose}
    >
      <IonHeader translucent>
        <IonToolbar className="darkTool">
          <IonTitle color="light">{!business ? 'Scan Now' : business.name}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={handleClose}>
              <IonIcon slot="icon-only" icon="close" color="light"/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="scannerHolder">
        <div className="">
        { checking ? 
          <IonGrid className="scanContent">
            <IonRow>
              <IonCol>
                <p className="ion-text-center ion-padding"><IonSpinner name="crescent" /></p>
              </IonCol>
            </IonRow>
          </IonGrid>
        : business ?
          <IonGrid className="bizContent">
            <IonRow>
              <IonCol>
                <div className="qrHolder">
                  <div className="qrCode">
                  <p className="ion-text-center"><QRCode fgColor={'#000'} level={'L'} size={256} value={user.uid}/></p></div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        : platform === 'mobileweb' && ready ?
        <div className="scanContent">
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
          />
        </div>
        : null
        }
        </div>
      </IonContent>
      { business ? 
      <IonButton onClick={clearPass}>Clear Pass</IonButton>
      : null }
    </IonModal>
    </>
  );
};

export default PassHelp;
